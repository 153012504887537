import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import ArticleMetadata from "./ArticleMetadata"
import Img, { GatsbyImageProps } from "gatsby-image"

interface Props {
  articleUrl: string
  authorName: string
  heroImage?: GatsbyImageProps
  publicationDate: Date
  summary: string
  title: string
}

const ArticleSummary: FunctionComponent<Props> = ({
  articleUrl,
  authorName,
  heroImage,
  publicationDate,
  summary,
  title,
}) => {
  return (
    <article className="br-prose">
      {heroImage && (
        <div className="mb-8">
          <Link className="br-image-link" to={articleUrl}>
            <Img fluid={heroImage.fluid} />
          </Link>
        </div>
      )}

      <div className="mb-2">
        <ArticleMetadata
          authorName={authorName}
          publicationDate={publicationDate}
        />
      </div>

      <div className="mb-6 xxxl:mb-8">
        <h2 className="flush-t">
          <Link to={articleUrl}>{title}</Link>
        </h2>
        <p>{summary}</p>
      </div>
    </article>
  )
}

export default ArticleSummary
