import React, { FunctionComponent } from "react"
import { Link } from "gatsby"
import ArticleMetadata from "./ArticleMetadata"
import Img, { GatsbyImageProps } from "gatsby-image"

interface Props {
  articleUrl: string
  authorName: string
  heroImage: GatsbyImageProps
  publicationDate: Date
  summary: string
  title: string,
  hideSummary?: boolean
}

const SimilarArticle: FunctionComponent<Props> = ({
  articleUrl,
  authorName,
  heroImage,
  publicationDate,
  summary,
  title,
  hideSummary
}) => (
  <article className="br-prose br-col-gap grid grid-cols-3 row-gap-8">
    <div className="col-start-1 col-span-3 row-start-2 sm:col-span-2 sm:row-start-1 xxl:col-start-1 xxl:col-span-3 xxl:row-start-2">
      <div className="mb-2 xxl:mb-4">
        <ArticleMetadata
          authorName={authorName}
          publicationDate={publicationDate}
        />
      </div>

      <h3 className="flush-t font-bold font-serif text-3 sm:text-4 xxl:text-6">
        <Link to={articleUrl}>{title}</Link>
      </h3>

      {!hideSummary && <div className="hidden sm:block sm:mt-4 sm:text-2 xxl:text-3 xxl:mt-6">
        <p>{summary}</p>
      </div>}
    </div>

    <div className="col-start-1 col-span-3 row-start-1 sm:col-span-2 xxl:col-start-1 xxl:col-span-3 xxl:row-start-1">
      <Link className="br-image-link" to={articleUrl}>
        <Img fluid={heroImage.fluid} />
      </Link>
    </div>
  </article>
)

export default SimilarArticle
