import React, { FunctionComponent } from "react"
import ArticleSummary from "../../components/blog/ArticleSummary"
import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Seo from "../../components/Seo"
import Container from "../../components/Container"
import Pill from "../../components/Pill"
import { ArticleSummary as ArticleSummaryResult } from "../../types/QueryResults"
import { MDXRenderer } from "gatsby-plugin-mdx"
import TopArticles from "../../components/blog/TopArticles"

interface QueryResult {
  page: {
    frontmatter: {
      title: string
      seo: {
        title: string
        description: string
      }
    }
    body: string
  }
  firstArticle: {
    edges: { node: ArticleSummaryResult }[]
  },
  otherArticles: {
    edges: { node: ArticleSummaryResult }[]
  },
}

interface Props {
  data: QueryResult
}

const articleData = ({ node }) => {
  const fm = node.frontmatter
  return Boolean(fm) ? {
    articleUrl: `/blog/${fm.slug}`,
    authorName: fm.author,
    heroImage: fm.heroImage?.childImageSharp,
    publicationDate: new Date(fm.date),
    summary: fm.summary,
    title: fm.title,
  } : null
}

const BlogIndex: FunctionComponent<Props> = ({ data }) => {
  const { page, firstArticle, otherArticles } = data

  const mainArticle = articleData(firstArticle && firstArticle.edges[0] ? firstArticle.edges[0] : {})
  const restArticles = otherArticles.edges.slice(1).map(articleData)

  return (
    <Layout disableIntercom>
      <Seo
        description={page.frontmatter.seo.description}
        pathname="/blog"
        title={page.frontmatter.seo.title}
      />

      <header>
        <Pill bgClass="bg-green-100" variant="bottom-right">
          <Container>
            <div className="br-prose py-12 xxl:py-24">
              <div className="br-col-gap md:grid md:grid-cols-12">
                <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
                  <h1 className="mb-8 text-center">{page.frontmatter.title}</h1>
                  <div className="text-center">
                    <MDXRenderer>{page.body}</MDXRenderer>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </Pill>
      </header>


      <section className="mb-12 pt-12 xxl:mt-16 xxl:pt-16">
        <Container>

          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <div className="br-col-gap grid grid-cols-2 row-gap-16">
                {mainArticle && (
                  <div className="col-span-2 mb-16 xxl:mb-24">
                    <ArticleSummary
                      articleUrl={mainArticle.articleUrl}
                      authorName={mainArticle.authorName}
                      heroImage={mainArticle.heroImage}
                      publicationDate={mainArticle.publicationDate}
                      summary={mainArticle.summary}
                      title={mainArticle.title}
                    />
                  </div>
                )}

              </div>
            </div>
          </div>
        </Container>
      </section>

      <TopArticles/>


      <section className="mb-12 py-12 xxl:mb-24 xxl:py-24">
        <Container>

          <div className="br-col-gap md:grid md:grid-cols-12">
            <div className="md:col-span-10 md:col-start-2 xxl:col-span-8 xxl:col-start-3">
              <div className="br-col-gap grid grid-cols-2 row-gap-16">
                {
                  restArticles && restArticles.map((article, index) => (
                    <div key={index} className="col-span-2 xl:col-span-1">
                      <ArticleSummary
                        articleUrl={article.articleUrl}
                        authorName={article.authorName}
                        heroImage={article.heroImage}
                        publicationDate={article.publicationDate}
                        summary={article.summary}
                        title={article.title}
                      />
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </Container>
      </section>


    </Layout>
  )
}

export const query = graphql`
  query ArticlesQuery {
    page: mdx(fileAbsolutePath: { regex: "//static/blog-index/" }) {
      frontmatter {
        title
        seo {
          description
          title
        }
      }
      body
    }
    firstArticle: allMdx(
      filter: { fileAbsolutePath: { regex: "//content/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
    ) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "YYYY-MM-DD")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            slug
            summary
          }
          id
        }
      }
    }
    otherArticles: allMdx(
      filter: { fileAbsolutePath: { regex: "//content/blog/" } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "YYYY-MM-DD")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            title
            slug
            summary
          }
          id
        }
      }
    }
  }
`

export default BlogIndex
