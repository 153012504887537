import { graphql, StaticQuery, PageProps, Link } from "gatsby"
import React, { FunctionComponent, Props } from "react"
import {
  Article as ArticleQueryResult,
  ArticleSummary as ArticleSummaryQueryResult
} from "../../types/QueryResults"
import Pill from "../Pill"
import Container from "../Container"
import SimilarArticle from "./SimilarArticle"

interface QueryResult {
  mdx: ArticleQueryResult
  allMdx: { edges: { node: ArticleSummaryQueryResult }[] }
}

interface PropsTopArticles {
  link?: string
}
interface PropsTopArticlesList {
  mdx: any
  allMdx: any
  link?: string
}

type ArticleProps = PageProps<QueryResult>


const query = graphql`
  query TopArticles {
    mdx(fileAbsolutePath: {regex: "//static/top-articles/"}) {
      frontmatter {
        title
        articles
      }
    }
    allMdx {
      edges {
        node {
          frontmatter {
            author
            date(formatString: "YYYY-MM-DD")
            heroImage {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 450) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            summary
            title
          }
        }
      }
    }
  }
`

function makeTopArticles (articles: { node: ArticleSummaryQueryResult }[]) {
  return articles.map(({ node }) => {
    const frontmatter = node.frontmatter

    return (
      <SimilarArticle
        key={node.id}
        articleUrl={`/blog/${frontmatter.slug}`}
        authorName={frontmatter.author}
        heroImage={frontmatter.heroImage.childImageSharp}
        publicationDate={new Date(frontmatter.date)}
        summary={frontmatter.summary}
        title={frontmatter.title}
        hideSummary
      />
    )
  })
}

const TopArticlesList: FunctionComponent<PropsTopArticlesList> = ({mdx, allMdx, link }) => {
  const { title, articles } = mdx.frontmatter

  const topArticlesList = allMdx.edges.filter(({ node }) => {
    return articles.includes(node.frontmatter.slug)
  })

  const topArticlesNodes = makeTopArticles(topArticlesList)

  return (
    <aside className="mt-24">
      <Pill bgClass="bg-green-100" variant="top-right">
        <Container>
          <div className="br-col-gap py-12 md:grid md:grid-cols-12 md:py-16">
            <div className="md:col-span-10 md:col-start-2 xxxl:col-span-8 xxxl:col-start-3">
              <h2 className="font-bold font-serif mb-20 mt-10 text-center w-full">
                {title}
              </h2>

              <div className="br-col-gap grid grid-cols-3 items-start row-gap-8">
                {topArticlesNodes.map((article, index) => (
                  <div key={index} className="col-span-4 xxl:col-span-1">
                    {article}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </Pill>
      { link &&
      <Pill bgClass="bg-green-100" variant="bottom-left">
        <div className="br-prose mt-8 text-1 text-center xxl:text-2 mb-32"><Link to={link}>See all articles</Link></div>
      </Pill>
      }
    </aside>
  )
}

/**
 * Make the article component
 */
const TopArticles: FunctionComponent<PropsTopArticles> = ({link}) => {
  return (
    <StaticQuery
      query={query}
      render={data => <TopArticlesList link={link} {...data} />}
    />
  )
}

export default TopArticles
